import { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { AttachFile, Clear } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { Formik } from "formik";
import * as yup from "yup";
import Dropzone from "react-dropzone";
import { useHttp } from "../hooks/http.hook";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const supportSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  name: yup.string().required("required").min(1).max(100),
  helpTopic: yup.string().required("required"),
  msgDetails: yup.string().required("required").min(5).max(500),
  pictures: yup.array(),
});

const initialValuesSupport = {
  email: "",
  name: "",
  helpTopic: "",
  msgDetails: "",
  pictures: [] as any,
};

const SupportForm = ({ appInternalName }: any) => {
  const { palette } = useTheme();
  const [files, setFiles] = useState<File[]>([]);
  const { loading, request, error, clearError } = useHttp();
  const navigate = useNavigate();

  const emailInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    emailInputRef.current?.focus();
  }, []);

  const handleRemoveFile = (fileToRemove: File, setFieldValue: any) => {
    const updatedFiles = files.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);
    setFieldValue("pictures", updatedFiles); // Update the Formik field 'pictures'
  };

  const storage = getStorage();
  const uploadFiles = async (files: File[]) => {
    const uploadPromises = files.map(async (file) => {
      const storageRef = ref(storage, `supportForms/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      return getDownloadURL(snapshot.ref);
    });

    return Promise.all(uploadPromises);
  };

  const handleFormSubmit = async (values: any, onSubmitProps: any) => {
    clearError();
    try {
      const fileUrls = await uploadFiles(files);

      await addDoc(collection(db, "supportForms"), {
        email: values.email,
        name: values.name,
        helpTopic: values.helpTopic,
        msgDetails: values.msgDetails,
        createdAt: new Date().toISOString(),
        fileUrls,
      });

      onSubmitProps.resetForm();
      setFiles([]);
      navigate("success");
      console.log("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesSupport}
      validationSchema={supportSchema}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ width: "100%" }}>
            {error ? <Error text={error} /> : null}

            <Header text="Your email" />
            <TextField
              inputRef={emailInputRef}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ width: "100%", mt: 2 }}
            />

            <Header text="Your name" />
            <TextField
              onChange={handleChange}
              value={values.name}
              name="name"
              error={Boolean(touched.name) && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              sx={{ width: "100%", mt: 2 }}
            />

            <Header text="Type of inquiry" />
            <RadioGroup
              name="helpTopic"
              value={values.helpTopic}
              onChange={handleChange}
              sx={{ mt: 2 }}
            >
              <FormControlLabel
                value="Questions and Comments about the App"
                control={<Radio />}
                label="Questions and Comments about the App"
              />
              <FormControlLabel
                value="How to Manage or Cancel Your Subscription"
                control={<Radio />}
                label="How to Manage or Cancel Your Subscription"
              />
            </RadioGroup>
            {touched.helpTopic && errors.helpTopic ? (
              <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                {errors.helpTopic}
              </p>
            ) : null}

            <Header text="Details of your request" />
            <TextField
              multiline
              rows={4}
              onChange={handleChange}
              value={values.msgDetails}
              name="msgDetails"
              placeholder="Your detailed step-by-step description of the issue will help us solve your problem faster!"
              error={Boolean(touched.msgDetails) && Boolean(errors.msgDetails)}
              helperText={touched.msgDetails && errors.msgDetails}
              sx={{ width: "100%", mt: 2 }}
            />

            <Dropzone
              multiple={true}
              maxSize={MAX_FILE_SIZE_BYTES}
              accept={{
                "image/png": [".png"],
                "image/jpeg": [".jpeg"],
                "image/jpg": [".jpg"],
                "text/html": [".html", ".htm"],
              }}
              onDrop={(acceptedFiles, fileRejections) => {
                const totalExistingSize = files.reduce(
                  (total, file) => total + file.size,
                  0
                );
                const totalNewSize = acceptedFiles.reduce(
                  (total, file) => total + file.size,
                  0
                );
                if (totalExistingSize + totalNewSize > MAX_FILE_SIZE_BYTES) {
                  alert(
                    `Total size of files should not exceed ${MAX_FILE_SIZE_MB}MB.`
                  );
                } else {
                  const imageFiles = acceptedFiles.filter((file) =>
                    ["image/jpeg", "image/jpg", "image/png"].includes(file.type)
                  );
                  setFiles([...files, ...imageFiles]);
                  setFieldValue("pictures", [...files, ...imageFiles]);
                }
              }}
              onDropRejected={(fileRejections) => {
                fileRejections.forEach((fr) =>
                  fr.errors.forEach((err) =>
                    alert(
                      err.code === "file-too-large"
                        ? `File size must be ${MAX_FILE_SIZE_MB}MB or less`
                        : err.message
                    )
                  )
                );
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  {...getRootProps()}
                  border={`1px solid grey`}
                  mt={4}
                  p={1}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  borderRadius={1}
                >
                  <input {...getInputProps()} />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AttachFile sx={{ color: palette.primary.main }} />
                    <Typography fontSize={"1rem"} marginLeft={1}>
                      Attach screenshots (optional)
                    </Typography>
                  </Box>
                </Box>
              )}
            </Dropzone>

            <Box sx={{ mt: 2 }}>
              {files.map((file, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                  <Typography>{file.name}</Typography>
                  <Clear
                    sx={{
                      ml: 2,
                      cursor: "pointer",
                      color: "grey",
                      fontSize: "1rem",
                    }}
                    onClick={() => handleRemoveFile(file, setFieldValue)}
                  />
                </Box>
              ))}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <LoadingButton
                type="submit"
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                sx={{ width: "100%", maxWidth: 190, height: 56 }}
              >
                <span>Send</span>
              </LoadingButton>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

const Header = ({ text }: { text: string }) => (
  <Box sx={{ display: "flex", flexDirection: "row", mt: 4 }}>
    <Typography fontSize={"1rem"}>{text}</Typography>
    <Typography color={"#B3261E"} marginLeft={"6px"}>
      *
    </Typography>
  </Box>
);

const Error = ({ text }: { text: string }) => (
  <Alert severity="error">
    <AlertTitle>Error</AlertTitle>
    {text}
    <Typography variant="body2">
      Please, send us your request directly: app.service.notificator@gmail.com
    </Typography>
  </Alert>
);

export default SupportForm;
