// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBmicjPX5yU2txhnJLl_FIZi9iAhgWj_U4",
  authDomain: "sa-website-70b08.firebaseapp.com",
  projectId: "sa-website-70b08",
  storageBucket: "sa-website-70b08.appspot.com",
  messagingSenderId: "248849940248",
  appId: "1:248849940248:web:2179c641f8c22203f4286d",
  measurementId: "G-DFHFFSJG7E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
